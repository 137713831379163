h1,
h2,
h3,
h4 {
  color: #111111;
}

h1,
h2,
h3,
h4,
h5,
p,
dl {
  margin-bottom: 16px;
  padding: 0;
}

h1 {
  font-size: 48px;
  line-height: 54px;
}

h2 {
  font-size: 36px;
  line-height: 42px;
}

h1,
h2 {
  // border-bottom: 1px solid #efeaea;
  padding-bottom: 10px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-size: 21px;
  line-height: 26px;
}

h5 {
  font-size: 18px;
  list-style: 23px;
}

a {
  color: #0099ff;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

a:hover {
  text-decoration: none;
  color: #ff6600;
}

ul,
ol {
  padding: 0;
  padding-left: 24px;
  margin: 0;
}

li {
  line-height: 24px;
}

p,
ul,
ol {
  font-size: 16px;
  line-height: 24px;
}

ol ol,
ul ol {
  list-style-type: lower-roman;
}

code,
pre {
  border-radius: 3px;
  background-color: #f7f7f7;
  color: inherit;
  padding: 0 4px;
}

code {
  font-family: Consolas, Monaco, Andale Mono, monospace;
  margin: 0 2px;
}

pre {
  line-height: 1.7em;
  overflow: auto;
  padding: 6px 10px;
  border-left: 5px solid #6ce26c;
}

pre > code {
  border: 0;
  display: inline;
  max-width: initial;
  padding: 0;
  margin: 0;
  overflow: initial;
  line-height: inherit;
  font-size: 0.85em;
  white-space: pre;
  background: 0 0;
}

code {
  color: #666555;
}

/** markdown preview plus 对于代码块的处理有些问题, 所以使用统一的颜色 */
/*code .keyword {
  color: #8959a8;
}

code .number {
  color: #f5871f;
}

code .comment {
  color: #998
}*/

aside {
  display: block;
  float: right;
  width: 390px;
}

blockquote {
  border-left: 0.5em solid #eee;
  padding: 0 0 0 2em;
  margin-left: 0;
}

blockquote cite {
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
}

blockquote cite:before {
  content: '\2014 \00A0';
}

blockquote p {
  color: #666;
}

hr {
  text-align: left;
  color: #999;
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
}

dl {
  padding: 0;
}

dl dt {
  padding: 10px 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: bold;
}

dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

dd {
  margin-left: 0;
}

/* Code below this line is copyright Twitter Inc. */

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
}

/* override default chrome & firefox settings */
input:not([type='image']),
textarea {
  box-sizing: content-box;
}

input[type='search'] {
  box-sizing: content-box;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

label,
input,
select,
textarea {
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 18px;
}

input[type='checkbox'],
input[type='radio'] {
  cursor: pointer;
  margin-bottom: 0;
}

input[type='text'],
input[type='password'],
textarea,
select {
  display: inline-block;
  width: 210px;
  padding: 4px;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  height: 18px;
  color: #808080;
  border: 1px solid #ccc;
  border-radius: 3px;
}

select,
input[type='file'] {
  height: 27px;
  line-height: 27px;
}

textarea {
  height: auto;
}

/* grey out placeholders */
:-moz-placeholder {
  color: #bfbfbf;
}

::-webkit-input-placeholder {
  color: #bfbfbf;
}

input[type='text'],
input[type='password'],
select,
textarea {
  transition: border linear 0.2s, box-shadow linear 0.2s;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

input[type='text']:focus,
input[type='password']:focus,
textarea:focus {
  outline: none;
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(82, 168, 236, 0.6);
}

/* buttons */
button {
  display: inline-block;
  padding: 4px 14px;
  font-size: 13px;
  line-height: 18px;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #0064cd;
  background-repeat: repeat-x;
  background-image: linear-gradient(to top, #049cdb, #0064cd);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #004b9a;
  border-bottom-color: #003f81;
  transition: 0.1s linear all;
  border-color: #0064cd #0064cd #003f81;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}

button:hover {
  color: #fff;
  background-position: 0 -15px;
  text-decoration: none;
}

button:active {
  box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

table {
  *border-collapse: collapse;
  /* IE7 and lower */
  border-spacing: 0;
  width: 100%;
}

table {
  border: solid #ccc 1px;
  border-radius: 6px;
}

table tr:hover {
  background: #fbf8e9;
  transition: all 0.1s ease-in-out;
}

table td,
.table th {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

table th {
  background-color: #dce9f9;
  background-image: linear-gradient(to top, #ebf3fc, #dce9f9);
  border-top: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  padding: 5px;
}

table td:first-child,
table th:first-child {
  border-left: none;
}

table th:first-child {
  border-radius: 6px 0 0;
}

table th:last-child {
  border-radius: 0 6px 0 0;
}

table th:only-child {
  border-radius: 6px 6px 0 0;
}

table tr:last-child td:first-child {
  border-radius: 0 0 0 6px;
}

table tr:last-child td:last-child {
  border-radius: 0 0 6px;
}
