.indexBox {
  color: #141414;
  min-height: calc(100vh - 50px);
  padding-bottom: 50px;
  background-image: linear-gradient(to top, #eef2ff, #fff, #cffafe);
  min-width: 1200px;
  position: relative;

  .index {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: auto;
    color: #111111;
    .headerBox {
      height: 64px;
      .headerBox2 {
        background-color: #cffafe;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 9;
      }
      .header {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        height: 50px;
        > a:nth-child(1) {
          > img {
            cursor: pointer;
            width: 30px;
            height: 30px;
            margin-right: 8px;
            margin-top: 11px;
          }
        }
        > .septalLine {
          width: 2px;
          height: 22px;
          background-color: #a2a2a2;
          margin: 15px 12px 0 2px;
        }
        .logo {
          height: 50px;
          display: flex;
          align-items: center;
          font-size: 30px;
          font-weight: bold;
          > .beta {
            color: #fff;
            font-size: 12px;
            line-height: 1;
            background-color: #18c256;
            padding: 2px 4px;
            font-weight: 500;
            position: relative;
            top: -4px;
            margin-left: 8px;
          }
        }
        .subtitle {
          flex: 1;
          display: inline-flex;
          align-items: flex-end;
          padding-bottom: 10px;
          margin-left: 26px;
          font-size: 16px;
          // color: #6b7280;
          > a {
            color: #002c8c;
          }
        }
        .login {
          padding-right: 10px;
          margin-top: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .user {
            height: 30px;
          }
          .totalQuota {
            padding-right: 10px;
            color: #010101;
          }
          .logout {
            display: none;
            position: absolute;
            left: 0;
            top: 44px;
            background-color: #fff;
            box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
            width: 150px;
            line-height: 34px;
            padding: 0 10px;
            border-radius: 4px;
            &:hover {
              background-color: rgba(255, 255, 255, 0.7);
            }
          }
          &:hover {
            > .logout {
              display: block !important;
            }
          }
        }
        .getAccess {
          height: 32px;
          display: flex;
          align-items: center;
          color: #fff;
          padding: 0 14px;
          border-radius: 16px;
          background-color: #000;
          border: 1px solid #000;
          box-sizing: border-box;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          position: relative;
          &:hover {
            background-color: #fff;
            color: #000;
            > .getAccessImg {
              display: block;
            }
          }
          > .getAccessImg {
            display: none;
            position: absolute;
            z-index: 9;
            right: 0;
            top: 40px;
            background-color: #fff;
            padding: 10px;
            > span {
              display: block;
              text-align: justify;
              font-weight: 500;
              font-size: 14px;
              color: #111111;
            }
            > img {
              width: 240px;
            }
          }
        }
      }
    }
    .inputBox {
      display: flex;
      align-items: center;
      flex-direction: column;
      .introduce {
        margin-top: 80px;
        font-size: 60px;
        text-align: center;
        font-weight: bold;
        > .introduceHighlight {
          color: transparent;
          background-clip: text;
          background-image: linear-gradient(to right, #3b82f6, #16a34a, #6366f1);
        }
      }
      .key {
        margin-top: 40px;
        width: 800px;
        color: rgb(57, 65, 76);
        :global {
          input {
            width: 100%;
            margin: 3px 0 0 6px;
            box-shadow: none;
          }
        }
      }
      .upload {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 40px;
        width: 1200px;
        .dragger {
          transition: width 0.2s;
          height: 200px;
          width: 600px;
          position: relative;
          .handles {
            position: absolute;
            left: 10px;
            top: 10px;
            display: flex;
            gap: 10px;
            :global(.ant-dropdown-trigger) {
              color: #4096ff;
              border-color: #4096ff;
            }
          }
        }
        .uploadImg {
          border-radius: 10px;
          border: 1px dashed #d9d9d9;
          box-sizing: border-box;
          margin-left: 20px;
          max-width: 700px;
          position: relative;
          overflow: hidden;
          > img {
            object-fit: contain;
            min-width: 200px;
            max-width: 100%;
            max-height: 500px;
          }
          > .uploadState {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
            border-radius: 4px 0 0;
            background-color: rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }
    .content {
      margin-top: 60px;
      padding-bottom: 30px;
      display: flex;
      height: 600px;
      position: relative;
      > .textAreaBox {
        flex: 1;
        position: relative;
        .textArea {
          height: 100%;
        }
        .copy {
          cursor: pointer;
          position: absolute;
          top: 1px;
          right: 1px;
          padding: 5px 8px;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
        }
        :global {
          textArea {
            resize: none;
            font-size: 15px;
            overflow-y: auto;
            padding: 26px 20px;
            box-sizing: border-box;
            background-color: transparent;
            border: 1px dashed #d9d9d9 !important;
            border: none;
            border-radius: 0;
            box-shadow: none;
          }

          .ant-input-focused,
          :where(.css-dev-only-do-not-override-ixblex).ant-input:hover,
          :where(.css-dev-only-do-not-override-ixblex).ant-input:focus,
          :where(.css-dev-only-do-not-override-ixblex).ant-input-focused {
            box-shadow: none;
            border-color: #ccc !important;
          }
        }
      }

      > .view {
        font-size: 15px;
        overflow-y: auto;
        padding: 10px 20px;
        box-sizing: border-box;
        background-color: transparent;
        border: 1px dashed #d9d9d9;
        margin-left: 8px;
        flex: 1;
        word-wrap: break-word;
        word-break: normal;
      }

      .download {
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: -110px;
        z-index: 2;
        display: flex;
        align-items: center;

        .downloadBtn{
          margin-right: 4px;
        }
        .downloadTip{
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid rgb(229, 231, 235);
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e7ebf8;
    text-align: center;
    height: 50px;
    line-height: 50px;
    min-width: 1200px;
    color: rgb(107, 114, 128);
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
      Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    > a {
      padding: 0 4px;
      color: rgb(31, 41, 55);
      &:hover {
        text-decoration: underline;
        color: rgb(31, 41, 55);
      }
    }
  }
}
