.loginBox {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  .login {
    position: relative;
    background-color: #fff;
    padding: 20px 30px 10px;
    width: 350px;
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #434343;
      text-align: center;
      margin-bottom: 20px;
    }
    .inputs {
      margin-top: 10px;
    }
    .inputBox {
      display: flex;
      margin-top: 16px;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 30px;
        border: 1px solid #d1d2d0;
        box-sizing: border-box;
        > img {
          width: 16px;
        }
      }
      > input {
        flex: 1;
        border: 1px solid #d1d2d0;
        height: 40px;
        outline: none !important;
        padding: 0 10px;
        box-sizing: border-box;
        border-left: 0;
        margin-bottom: 0;
        box-shadow: none;
        border-radius: 0;
        font-size: 15px;
        color: #010101;
      }
      .send {
        width: 80px;
        text-align: center;
        line-height: 40px;
        background-color: #2777f7;
        color: #fff;
        user-select: none;
        cursor: pointer;
      }
    }
    .agreement {
      user-select: none;
      cursor: pointer;
      display: flex;
      justify-items: center;
      margin: 20px 0 10px;
      > div {
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .button {
      background-color: #2777f7;
      margin-top: 30px;
      color: #fff;
      text-align: center;
      line-height: 44px;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .forgot {
    cursor: pointer;
    font-size: 14px;
    color: #595959;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .cancel {
    margin: 20px auto 10px;
    width: 30px;
    display: block;
    cursor: pointer;
  }
}

.a {
  &:hover {
    color: #0099ff;
  }
}
