.headerBox {
  height: 50px;
  .b {
    font-weight: 600;
  }
  .l {
    font-weight: initial;
  }
  .headerBox2 {
    background-color: #cffafe;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
  }
  .header {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 50px;
    > a:nth-child(1) {
      > img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-right: 8px;
        margin-top: 11px;
      }
    }
    > .septalLine {
      width: 2px;
      height: 22px;
      background-color: #a2a2a2;
      margin: 15px 12px 0 2px;
    }
    .logo {
      height: 50px;
      display: flex;
      align-items: center;
      font-size: 30px;
      font-weight: bold;
      > .beta {
        color: #fff;
        font-size: 12px;
        line-height: 1;
        background-color: #18c256;
        padding: 2px 4px;
        font-weight: 500;
        position: relative;
        top: -4px;
        margin-left: 8px;
      }
    }
    .subtitle {
      flex: 1;
      display: inline-flex;
      align-items: flex-end;
      padding-bottom: 10px;
      margin-left: 10px;
      font-size: 16px;
      > a {
        color: #002c8c;
      }
      > .navItem {
        margin-left: 15px;
        cursor: pointer;
      }
      > .menu {
        background-color: transparent;
        width: 100%;
        border: 0;
      }
    }
    .login {
      padding-right: 10px;
      margin-top: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .user {
        height: 30px;
      }
      .totalQuota {
        padding-right: 10px;
        color: #010101;
      }
      .userInfo {
        color: #434343;
        display: none;
        position: absolute;
        right: 0;
        top: 44px;
        box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
        min-width: 150px;
        border-radius: 4px;
        overflow: hidden;
        background-color: #fff;
        padding: 8px 10px;
        box-sizing: border-box;
        > .quota {
          box-sizing: border-box;
          .quotaTitle {
            font-weight: bold;
            line-height: 30px;
            font-size: 16px;
          }
          .quotaContent {
            color: #595959;
            font-size: 14px;
            > div {
              margin-bottom: 4px;
              white-space: nowrap;
              display: flex;
              font-weight: 600;
              > span:nth-child(1) {
                font-weight: 600;
                width: 34px;
              }
              > div:nth-child(2) {
                min-width: 40px;
              }
              > div:nth-child(3) {
                margin-left: 10px;
                color: #8c8c8c;
                font-weight: 400;
                font-size: 12px;
              }
              &:nth-child(2) {
                font-weight: initial;
              }
            }
          }
        }
        > .updatePassword {
          margin-bottom: 6px;
        }
      }
      &:hover {
        > .userInfo {
          display: block !important;
        }
      }
    }
    .getAccess {
      height: 32px;
      display: flex;
      align-items: center;
      color: #fff;
      padding: 0 14px;
      border-radius: 16px;
      background-color: #000;
      border: 1px solid #000;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      &:hover {
        background-color: #fff;
        color: #000;
        > .getAccessImg {
          display: block;
        }
      }
      > .getAccessImg {
        display: none;
        position: absolute;
        z-index: 9;
        right: 0;
        top: 40px;
        background-color: #fff;
        padding: 10px;
        > span {
          display: block;
          text-align: justify;
          font-weight: 500;
          font-size: 14px;
          color: #111111;
        }
        > img {
          width: 240px;
        }
      }
    }
  }
}


.modelsDropdown{
  width: 120px;
  position: relative;
  .tag{
    position: absolute;
    right: 0px;
    bottom: 9px;
    cursor: pointer;
  }
}