.price {
  background: linear-gradient(to top, #eef2ff, #fff, #cffafe);
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 700px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .introduce {
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    > .introduceHighlight {
      color: transparent;
      background-clip: text;
      background-image: linear-gradient(to right, #3b82f6, #16a34a, #6366f1);
    }
  }

  .productList {
    display: flex;
    align-items: center;
    width: 1100px;
    padding: 50px 0;
    box-sizing: border-box;
    .productItemWrap {
      cursor: pointer;
      background-color: rgb(57, 55, 56);
      border-radius: 16px;
      padding: 28px 20px;
      flex: 1;
      color: rgb(62, 140, 118);
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      margin-left: 40px;
      &:first-child {
        margin-left: 0px;
      }
      .productItem {
        padding: 0 14px;
        box-sizing: border-box;
        .icon {
          margin-right: 14px;
        }
        > div {
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .buyBtn {
        margin-top: 50px;
        width: 100%;
        height: 70px;
        background-color: rgb(62, 140, 118);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        font-size: 22px;
      }
    }
  }

  .hint{
    color: #434343;
    font-size: 16px;
  }
}
